import {Component, OnInit} from '@angular/core';
import {AppStateService} from "../../services/app-state.service";
import {RECHARGE_STATE_NAME} from "../../utility/recharge.state";
import {BannerConfigData} from "../../data/config-data";
import {AppRestService} from "../../services/app-rest.service";

@Component({
  selector: 'helper-section',
  templateUrl: './helper-feed.component.html'
})
export class HelperFeedComponent implements OnInit {

  bannerPrefsLeft: BannerConfigData = {
    src: $localize`:@@banerLeftSrc:https://media-play.pl/binaries/content/gallery/play/doladowania/bannery/cyklicznedoladowania.png`,
    link: $localize`:@@banerLeftLink:https://www.play.pl/nowy/uslugi/doladowania-cykliczne`,
    alt: $localize`:@@banerLeftAlt:Doładowania Cykliczne`
  };

  bannerPrefsRight: BannerConfigData = {
    src: $localize`:@@banerRightSrc:https://media-play.pl/binaries/content/gallery/play/doladowania/bannery/doladowania-pl.png`,
    link: $localize`:@@banerRightLink:https://www.play.pl/kampania/extra-gb`,
    alt: $localize`:@@banerRightAlt:5000gb`
  };


  amountState: boolean = false;

  constructor(private stateService: AppStateService,public restService: AppRestService) { }

  ngOnInit() {
    this.stateService.rechargeStateNotifier
      .subscribe(state => {
        this.amountState = (state == RECHARGE_STATE_NAME.AMOUNT);
      });
  }
  checkBanner(src: string) {
    if(src != null && src != "''"){
      return true;
    }
    else{
      return false;
    }
  }
}
